import React, { useState, useRef, useContext } from 'react';

import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import classNames from 'classnames';
import { AnimatePresence, motion, useMotionValue } from 'framer-motion';

import SlideShowNav from '../SlideShowNav/SlideShowNav';
import ButtonPrimaryEllipse from '../ButtonPrimaryEllipse';
import ButtonSecondaryEllipse from '../ButtonSecondaryEllipse';
import { GradientContext, BackgroundContext } from '../../containers/BasePage';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { trackReadMore } from '../../utils/dataLayer';
import PhotoCred from '../PhotoCred';
import Vimeo from '@u-wave/react-vimeo';
import s from './Stories.module.scss';
import { SwiperSlide } from 'swiper/react';

import dynamic from 'next/dynamic';

const SlideShow = dynamic(() => import('../SlideShow'), { ssr: false });

const Stories = ({
  tagline,
  titleTop,
  titleBottom,
  anchorLink,
  items = [],
  backgroundColor = '',
}) => {
  const [index, setIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalVideoUrl, setModalVideoUrl] = useState('');
  const itemRefs = useRef([]);
  const gradient = useContext(GradientContext);
  const background = useContext(BackgroundContext) || {};

  const [ref, isVisible] = useIntersectionObserver(0, undefined, true);
  const { whiteBackground } = background || false;
  const { t } = useTranslation();

  const handleModalOpen = (videoUrl) => {
    document.body.style.overflow = 'hidden';
    setModalVideoUrl(videoUrl);
    setShowModal(true);
  };

  const handleModalClose = () => {
    document.body.style.overflow = 'inherit';
    setModalVideoUrl('');
    setShowModal(false);
  };

  const rootClasses = classNames(s.Root, s[gradient], {
    [s.White]: backgroundColor !== 'black' && whiteBackground,
  });

  const taglineClasses = classNames(s.Tagline, 'grid', {
    [s.Visible]: isVisible,
  });

  const titleClasses = classNames(s.Title, 'grid', {
    [s.Visible]: isVisible,
  });

  const titleMobileClasses = classNames(s.TitleMobile, {
    [s.Visible]: isVisible,
  });

  return (
    <section className={rootClasses} id={anchorLink} ref={ref}>
      <div className={taglineClasses}>
        <div className={s.TaglineText}>{tagline}</div>
      </div>
      <h2 className={titleMobileClasses}>
        <span className={titleClasses}>
          <span className={s.TitleContainer}>
            <span className={s.TitleTop}>{titleTop}</span>
            <span className={s.TitleBottom}>{titleBottom}</span>
          </span>
        </span>
      </h2>
      <h2 className={s.TitleDesktop}>
        <span className={titleClasses}>
          <span className={s.TitleTop}>{titleTop}</span>
          <span className={s.TitleBottom}>{titleBottom}</span>
        </span>
      </h2>
      <SlideShow
        breakpoints={{
          100: {
            slidesPerView: 1,
            spaceBetween: 24,
          },
          495: {
            slidesPerView: 'auto',
            spaceBetween: 20,
          },
          820: {
            slidesPerView: 'auto',
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 'auto',
            spaceBetween: 80,
          },
        }}
        onSlideChange={(e) => {
          setIndex(e.activeIndex);
        }}>
        {items.length > 0 &&
          items.map((item, i) => {
            const hasVideo = !!item?.video?.url;
            const hasImage = !!item?.image.url;
            const hasImageOrVideo = hasVideo || hasImage;
            const itemClasses = classNames(s.Item, {
              [s.Visible]: isVisible,
              [s.ImageOrVideo]: hasImageOrVideo,
              // [s.Video]: hasVideo,
              [s.OnlyText]: !hasImage && !hasVideo,
              [s.MediaAndText]: item.text && hasImageOrVideo,
              [s.FirstItem]: i === 0,
              [s.LastItem]: i === items.length - 1,
            });

            const isExternal =
              item?.link?.href?.indexOf('http://') !== -1 ||
              item?.link?.href?.indexOf('https://') !== -1;
            const imageStyle = { objectFit: 'cover' };

            const handleTrackReadMore = (e) => {
              e.preventDefault();

              trackReadMore(item?.title, 'Stories');

              setTimeout(() => {
                window.location.href = item?.link?.href;
              }, 200);
            };
            return (
              <SwiperSlide
                className={itemClasses}
                key={i}
                ref={(el) => (itemRefs.current[i] = el)}>
                {(hasVideo || hasImage) && (
                  <div div className={s.ItemImageContainer}>
                    <Image
                      src={item?.image?.url}
                      alt={item?.image?.alt}
                      title={item?.image?.title}
                      style={imageStyle}
                      draggable={false}
                      fill
                    />
                    {item?.image?.credit && (
                      <PhotoCred
                        credit={item?.image?.credit}
                        modifiers={['Stories']}
                      />
                    )}
                  </div>
                )}
                <motion.div
                  className={`${s.ItemContent} ${
                    backgroundColor !== 'black' && whiteBackground
                      ? 'white-glass'
                      : 'black-glass'
                  }`}>
                  <div className={s.ItemTitle}>{item?.title}</div>
                  {!!item?.text && (
                    <div className={s.ItemText}>{item?.text}</div>
                  )}
                  {!!item?.link?.href && (
                    <ButtonPrimaryEllipse
                      asLink
                      href={item?.link?.href}
                      onColor={
                        backgroundColor !== 'black' && whiteBackground
                          ? 'white'
                          : 'black'
                      }
                      onClick={handleTrackReadMore}
                      icon={
                        isExternal
                          ? 'icon-external-white'
                          : 'icon-arrow-right-white'
                      }
                      modifiers={['StoriesLink']}
                      iconDimensions={isExternal ? [18, 18] : [20, 20]}>
                      <span className={s.LinkText}>
                        {t('Generic.readMore')}
                      </span>
                    </ButtonPrimaryEllipse>
                  )}
                  {!!hasVideo && (
                    <ButtonPrimaryEllipse
                      onClick={() => handleModalOpen(item?.video?.url)}
                      href={item?.video?.url}
                      icon={'icon-play'}
                      onColor={
                        backgroundColor !== 'black' && whiteBackground
                          ? 'white'
                          : 'black'
                      }
                      modifiers={['StoriesLink']}>
                      <span className={s.LinkText}>
                        {item?.video?.description}
                      </span>
                    </ButtonPrimaryEllipse>
                  )}
                </motion.div>
              </SwiperSlide>
            );
          })}
        {items.length > 1 && (
          <SlideShowNav
            indicators={items}
            indicatorIndex={index}
            isVisible={isVisible}
            setIndicatorIndex={setIndex}
            color={
              backgroundColor !== 'black' && whiteBackground ? 'white' : 'black'
            }
          />
        )}
      </SlideShow>
      <AnimatePresence transition={{ duration: 1.3 }} mode="wait">
        {showModal && (
          <motion.div
            className={s.ModalBackdrop}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.3,
            }}
            onClick={handleModalClose}>
            <motion.div
              className={s.ModalContent}
              onClick={(e) => e.stopPropagation()}
              initial={{ scale: 0, y: -100 }}
              animate={{ scale: 1, y: 0 }}
              exit={{ scale: 0, opacity: 0, y: -100 }}
              transition={{
                duration: 0.3,
              }}>
              <ButtonSecondaryEllipse
                onClick={handleModalClose}
                modifiers={['StoriesModal']}
                icon={'icon-cross-white'}
              />
              <Vimeo
                className={s.ModalVideoContainer}
                video={modalVideoUrl}
                autoplay
              />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};

Stories.propTypes = {};

Stories.defaultProps = {};

export default Stories;
